import axios from 'axios'

export enum FormType {
  TRUST_US_A_PROJECT = 'TRUST_US_A_PROJECT',
  HIRE_STUDENT = 'HIRE_STUDENT',
  HIRE_INTERN = 'HIRE_INTERN',
  MEETUP = 'MEETUP',
  DOWNLOAD_PAMPHLET = 'DOWNLOAD_PAMPHLET',
  TALK_SESSION = 'TALK_SESSION',
  DOWNLOAD_TUITION = 'DOWNLOAD_TUITION',
}

export default function submitForm(formType: FormType, data: any) {
  return axios.request({
    method: 'post',
    // url: plateform === "LEMLIST" ? lemlistUrl : n8nUrl,
    url: '/api/forms',
    data: { ...data, formType },
  })
}
