/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import ListItem from '../../atoms/listItem'
import { FillCaretRight } from '../../atoms/icons'

type Props = { items: string[]; icon?: any }

const List: FC<Props> = ({ items, icon = <FillCaretRight /> }) => (
  <ul className='sm:text-xl whitespace-normal'>
    {items.map((item, index) => (
      <ListItem key={index.valueOf()} item={item} icon={icon} />
    ))}
  </ul>
)

export default List
