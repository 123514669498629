export const formatAirtablePhoneNumber = (phone: string): string =>
  `+243${phone.replaceAll('-', '')}`

export const getTrainingNameByKey = (key: string): string => {
  switch (key) {
    case 'smd':
      return 'Marketing Digital'
    case 'dev-web':
      return 'Développeur Web'
    default:
      return ''
  }
}

export const canDisplayPromotionalPrice = (trainingInfos: any) => {
  if (!trainingInfos?.fees?.promotional) return false
  if (trainingInfos?.fees?.promotionEndDate) {
    return new Date(trainingInfos?.fees?.promotionEndDate).getTime() > new Date().getTime()
  }
  return true
}

export const calculatePromotion = (trainingInfos: any) =>
  parseInt(trainingInfos.fees?.tuition, 10) - parseInt(trainingInfos.fees?.promotional, 10)
