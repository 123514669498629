/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import type { GatsbyLinkProps } from 'gatsby'

const isExternalLink = (path: string) =>
  path?.startsWith(`http://`) || path?.startsWith(`https://`) || path?.startsWith(`//`)

interface Props extends GatsbyLinkProps<Props> {}

const Link: FC<Props> = ({ children, ...props }: React.PropsWithoutRef<GatsbyLinkProps<Props>>) => {
  if (props.target === '_blank') {
    return (
      <a {...props} href={props.to} rel='noopener noreferrer' target='_blank'>
        {children}
      </a>
    )
  }

  if (isExternalLink(props.to)) {
    return (
      <a {...props} href={props.to}>
        {children}
      </a>
    )
  }

  return <GatsbyLink<Props> {...props}>{children}</GatsbyLink>
}

export default Link
