/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { navigate } from '@reach/router'
import { AiFillCheckCircle, AiOutlineCloudDownload } from 'react-icons/ai'
import globalClassNames from '../../../styles/globalClassNames'
import List from '../../molecules/list'
import CustomInput from '../../atoms/customInput'
import Button from '../../atoms/button'
import PopupModal from '../../molecules/PopupModal'
import submitForm, { FormType } from '../../../utils/formSubmission'
import Link from '../../atoms/link'
import { formatAirtablePhoneNumber } from '../../../utils/functions'

type Props = {
  onClose: () => void
}
const DownloadTuition = ({ onClose }: Props) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const formdata = useRef<any>({})
  const [status, setStatus] = useState<'error' | 'succed' | 'loading' | 'pending'>()
  const onChange = useCallback((e: any) => {
    if (e.target.value || e.target.value === '') {
      formdata.current = { ...formdata.current, [e.target.name]: e.target.value }
    }
  }, [])

  const onSubmit = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let isValid = true
    Array.from(e.currentTarget.elements).forEach((input: any) => {
      let inputIsValid = true
      const inputValue = formdata.current[input.getAttribute('name') || '']
      const inputIsRequired = input.classList.contains('required')
      const inputName = input.getAttribute('name')
      const inputPattern = input.getAttribute('data-pattern')

      if (inputIsRequired && !inputValue) {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-required`)
          ?.classList.remove('hidden')
      } else {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-required`)
          ?.classList.add('hidden')
      }

      if (inputIsRequired) {
        inputIsValid &&= inputValue !== undefined && inputValue !== ''
        isValid &&= inputValue !== undefined && inputValue !== ''
      }

      // error-invalid
      if (inputIsValid && inputPattern && !new RegExp(inputPattern).test(inputValue)) {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-invalid`)
          ?.classList.remove('hidden')
      } else {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-invalid`)
          ?.classList.add('hidden')
      }

      if (inputPattern) {
        isValid &&= new RegExp(inputPattern).test(inputValue)
      }
    })
    if (!isValid) {
      return
    }
    const phone = formatAirtablePhoneNumber(formdata.current.phone)
    const data = {
      ...formdata.current,
      'Whatsapp Number': phone,
      'Phone Number': phone,
      Origine: 'Site web Kadea Acedemy Téléchargement du Guide Financier',
      'First Name': formdata.current.firstName,
      'Last Name': formdata.current.lastName,
    }

    delete data.phone
    delete data.firstName
    delete data.lastName

    setStatus('loading')
    submitForm(FormType.DOWNLOAD_TUITION, data)
      .then(() => setTimeout(() => setStatus('succed'), 1000))
      .catch(() => setTimeout(() => setStatus('error'), 1000))
  }, [])

  useEffect(() => {
    const onkeydown = (e: any) => {
      if (e.key?.toLowerCase() === 'escape' || e.key?.toLowerCase() === 'esc') {
        onClose()
      }
    }
    document.addEventListener('keydown', onkeydown)
    document.body.classList.add('overflow-hidden')
    return () => {
      document.removeEventListener('keydown', onkeydown)
      document.body.classList.remove('overflow-hidden')
    }
  }, [onClose])

  useEffect(() => {
    if (status === 'succed') {
      navigate(`/download-tuition-fees?city=${formdata.current.Ville?.toLowerCase()}`)
    }
  }, [status])

  return (
    <PopupModal
      onClose={onClose}
      className='my-10 relative max-md:max-w-lg max-lg:max-w-2xl max-w-5xl bg-white shadow-xl grid lg:grid-cols-2 2xl:grid-cols-5 rounded-xl'
    >
      <div className='2xl:col-span-2 max-lg:rounded-t-xl lg:rounded-l-xl flex flex-col justify-center gap-y-5 bg-primary py-10 xl:py-16 px-5 text-white drop-shadow-2xl'>
        <h2 className={`${globalClassNames.h2} lg:leading-tight`}>
          Télécharge notre guide financier
        </h2>
        <List
          items={['Reçois le guide complet', 'Découvre nos options de financement']}
          icon={<AiFillCheckCircle className='text-white' />}
        />
        <p className='lg:text-lg'>
          Accède à notre guide pour découvrir en détail nos options de financement.
        </p>
      </div>
      <form
        ref={formRef}
        onSubmit={onSubmit}
        className='2xl:col-span-3 w-full py-5 px-2 sm:p-5 md:py-10 xl:py-16 flex flex-col gap-y-4'
      >
        {status === 'error' && (
          <p className='mt-4 mx-4'>
            Il semble que tu aies déjà ton guide, tu peux cliquer{' '}
            <Link
              className='text-primary'
              to={`/download-tuition-fees?city=${formdata.current.Ville?.toLowerCase()}`}
            >
              ici
            </Link>{' '}
            pour la télécharger 😉.
          </p>
        )}
        <CustomInput
          defaultValue={formdata.current.FirstName}
          label='Prénom'
          required
          onChange={onChange}
          type='text'
          name='firstName'
        />
        <CustomInput
          defaultValue={formdata.current.LastName}
          label='Nom'
          required
          onChange={onChange}
          type='text'
          name='lastName'
        />
        <CustomInput
          defaultValue={formdata.current.Email}
          label='Email'
          required
          onChange={onChange}
          type='email'
          name='Email'
        />
        <CustomInput
          defaultValue={formdata.current.phone}
          label='Téléphone'
          required
          onChange={onChange}
          type='tel'
          name='phone'
          id={`phone-${Date.now()}`}
        />
        <CustomInput
          defaultValue={formdata.current.Ville}
          label='Ville'
          required
          onChange={onChange}
          type='radio'
          name='Ville'
          placeholder='Choisis ta ville de résidence'
          options={['Lubumbashi', 'Kinshasa', 'Goma']}
        />
        <div className='mt-2 md:mt-5 flex gap-2 md:gap-5 justify-center'>
          <Button
            label='Télécharge'
            varient='primary'
            isLoading={status === 'loading' || status === 'succed'}
            icon={<AiOutlineCloudDownload />}
            showIcon
            onClick={() => {
              formRef?.current?.requestSubmit()
            }}
          />
        </div>
      </form>
    </PopupModal>
  )
}

export default DownloadTuition
