import React, { FC, useEffect, ReactNode } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

type Props = {
  className?: string
  children: ReactNode
  onClose: () => void
}
const PopupModal: FC<Props> = ({ className, children, onClose }) => {
  useEffect(() => {
    const onkeydown = (e: any) => {
      if (e.key?.toLowerCase() === 'escape' || e.key?.toLowerCase() === 'esc') {
        onClose()
      }
    }
    document.addEventListener('keydown', onkeydown)
    document.body.classList.add('overflow-hidden')
    return () => {
      document.removeEventListener('keydown', onkeydown)
      document.body.classList.remove('overflow-hidden')
    }
  }, [onClose])

  return (
    <section className='fixed z-[100] inset-0 w-full h-full bg-black/60 grid items-center justify-center overflow-y-scroll'>
      <div className={`relative my-10 ${className}`}>
        <button
          onClick={onClose}
          type='button'
          className='absolute z-20 top-0 right-1/2 lg:right-0 -translate-y-1/2 translate-x-1/2 w-8 lg:w-12 h-8 lg:h-12 flex justify-center items-center text-white bg-secondary hover:bg-primary shadow-2xl rounded-full'
        >
          <AiOutlineClose />
        </button>
        {children}
      </div>
    </section>
  )
}

PopupModal.defaultProps = {
  className: '',
}
export default PopupModal
