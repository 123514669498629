/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { AiOutlineSave } from 'react-icons/ai'
import { BsArrowLeft, BsFillPatchCheckFill } from 'react-icons/bs'
import { BiLoaderAlt } from 'react-icons/bi'
import globalClassNames from '../../../styles/globalClassNames'
import Button from '../../atoms/button'
import CustomInput from '../../atoms/customInput'
import PopupModal from '../../molecules/PopupModal'
import submitForm, { FormType } from '../../../utils/formSubmission'

type Props = {
  onClose: () => void
  formType: FormType
}

const FORMS = [
  {
    type: FormType.TRUST_US_A_PROJECT,
    title: 'Confiez-nous votre projet',
    description:
      "Profitez d'un développement gratuit de projet digital pour votre entreprise en participant à la formation pratique de nos apprenants.",
    list: [
      'Apprenants prêts à l’emploi',
      'Formation axée sur la pratique',
      'Déploiement rapide',
      'Profil adapté à vos besoins',
    ],
    maxW: 'max-w-xl',
    fieldsets: [
      {
        title: 'Données Personnelles',
        description: 'Avec qui serons-nous en contact pour tout les échanges futures',
        fields: [
          { asCol: false, name: 'firstName', type: 'text', required: false, label: 'Prénom' },
          { asCol: false, name: 'lastName', type: 'text', required: false, label: 'Nom' },
          {
            asCol: false,
            name: 'gender',
            type: 'radio',
            required: true,
            label: 'Genre',
            options: ['Femme', 'Homme'],
          },
          { asCol: false, name: 'phone', type: 'tel', required: true, label: 'Téléphone' },
          { asCol: false, name: 'email', type: 'email', required: true, label: 'Adresse email' },
          // { name: "post", type: "text", required: true, label: "Post/Fonction", },
        ],
      },
      {
        title: "Identité de l'Entreprise",
        description: 'Nous souhaitons en connître un peu plus sur ton entreprise',
        fields: [
          {
            asCol: false,
            name: 'companyName',
            type: 'text',
            required: true,
            label: 'Nom de lentreprise',
          },
          {
            asCol: false,
            name: 'companySector',
            type: 'text',
            required: true,
            label: "Secteur d'activité",
          },
          {
            asCol: false,
            name: 'companyCategory',
            type: 'radio',
            required: true,
            label: 'Catégorie',
            options: ['PME', 'Grand groupe', 'ONG', 'Public'],
            placeholder: 'sélection',
          },
          { asCol: false, name: 'companyEmail', type: 'email', required: true, label: 'Email' },
          {
            asCol: false,
            name: 'companyEmployees',
            type: 'radio',
            required: true,
            label: 'Taille',
            options: ['1-10', '11-50', '51-100', '100+'],
          },
          {
            asCol: true,
            name: 'companyNeeds',
            type: 'textarea',
            required: true,
            label: 'Expliquer le besoin souhaité',
          },
        ],
      },
      {
        title: 'Évaluation du besoin',
        description: '',
        fields: [
          {
            asCol: false,
            addWrapper: true,
            name: 'ecommerce',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'E-commerce',
          },
          {
            asCol: false,
            addWrapper: true,
            name: 'website',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'Site vitrine',
          },
          {
            asCol: false,
            addWrapper: true,
            name: 'managementSystem',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'Système de gestion',
          },
          {
            asCol: false,
            addWrapper: true,
            name: 'acquisitionClient',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'Acquisition client',
          },
          {
            asCol: false,
            addWrapper: true,
            name: 'socialNetwork',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'Réseaux sociaux',
          },
          {
            asCol: false,
            addWrapper: true,
            name: 'communityManager',
            type: 'radio',
            required: false,
            options: [0, 1, 2, 3, 4, 5],
            label: 'Community manager',
          },
          {
            asCol: false,
            name: 'hiring',
            type: 'select',
            required: false,
            label: 'Pensez-vous engager?',
            placeholder: 'Choisissez',
            options: [
              'Oui, je suis susceptible d’engager l’apprenant après la période d’accompagnement au sein de mon entreprise.',
              'Non, je ne suis pas susceptible d’engager l’apprenant après la période d’accompagnement au sein de mon entreprise.',
              'Cela dépendra de la performance de l’apprenant pendant la période d’accompagnement.',
              'Cela dépendra des besoins de l’entreprise à la fin de la période d’accompagnement.',
            ],
          },
        ],
      },
    ],
  },
  {
    type: FormType.HIRE_STUDENT,
    title: 'Embauchez un apprenant de Kadea Academy',
    description:
      'Nous connaissons les besoins des employeurs et nous connaissons les points forts de nos apprenants. Laissez-nous vous aider à trouver les talents dont votre entreprise a besoin pour se développer.',
    list: [
      'Apprenants prêts à l’emploi',
      'Formation axée sur la pratique',
      'Déploiement rapide',
      'Profil adapté à vos besoins',
    ],
    fieldsets: [
      {
        title: "Identité de l'Entreprise",
        description: 'Nous souhaitons en connître un peu plus sur ton entreprise',
        fields: [
          { name: 'Entreprise', type: 'text', required: true, label: 'Nom de lentreprise' },
          { name: 'Secteur', type: 'text', required: true, label: "Secteur d'activité" },
          {
            name: 'Category',
            type: 'radio',
            required: true,
            label: "Catégorie d'entreprise",
            options: ['PME', 'Grand groupe', 'ONG', 'Public'],
            placeholder: 'sélection',
          },
          { name: 'Adresse', type: 'text', required: true, label: "Adresse de l'entreprise" },
        ],
      },
      {
        title: 'Personne de contact',
        description: 'Avec qui serons-nous en contact pour tout les échanges futures',
        fields: [
          { name: 'Nom-du-Contact', type: 'text', required: false, label: 'Nom & Prénom' },
          { name: 'Email', type: 'email', required: true, label: 'Adresse email' },
          { name: 'Téléphone', type: 'tel', required: true, label: 'Téléphone' },
          { name: 'Post', type: 'text', required: true, label: 'Post/Fonction' },
        ],
      },
      {
        title: 'Quel type de profil souhaitez vous recruter',
        description: '',
        fields: [
          {
            asCol: false,
            name: 'Profil',
            type: 'radio',
            required: true,
            label: 'Expertise',
            options: ['Développeur', 'Marketing Digital '],
          },
          {
            asCol: false,
            name: 'Contrat',
            type: 'radio',
            required: true,
            label: "Secteur d'activité",
            options: ['CDI', 'CDD', 'Consultance'],
          },
          {
            asCol: true,
            conditioned: true,
            name: 'Critéres',
            type: 'textarea',
            required: false,
            label: 'Avez-vous des critères spécifiques',
          },
          { name: 'Projet', type: 'textarea', required: true, label: 'Projet' },
        ],
      },
    ],
  },
  {
    type: FormType.HIRE_INTERN,
    title: 'Recruter un stagiaire',
    description: '',
    list: [],
    maxW: 'max-w-2xl',
    fieldsets: [
      {
        title: "Identité de l'Entreprise",
        description: 'Nous souhaitons en connître un peu plus sur ton entreprise',
        fields: [
          { name: 'Entreprise', type: 'text', required: true, label: 'Nom de lentreprise' },
          { name: 'Secteur', type: 'text', required: true, label: "Secteur d'activité" },
          {
            name: 'Type-Entreprise',
            type: 'radio',
            required: true,
            label: 'Type d’organisation',
            options: ['Startup', 'PME', 'Grand groupe', 'ONG', 'Public'],
            placeholder: 'sélection',
          },
          {
            name: 'Taille-Entreprise',
            type: 'radio',
            required: true,
            label: "Taille de l'entreprise",
            options: ['1-10', '11-50', '51-100', '100+'],
          },
        ],
      },
      {
        title: 'Personne de contact',
        description: 'Avec qui serons-nous en contact pour tout les échanges futures',
        fields: [
          {
            asCol: false,
            name: 'Nom-du-Contact',
            type: 'text',
            required: true,
            label: 'Nom & Prénom',
          },
          { asCol: false, name: 'Email', type: 'email', required: true, label: 'Adresse email' },
          { asCol: false, name: 'Téléphone', type: 'tel', required: true, label: 'Téléphone' },
          { asCol: false, name: 'Post', type: 'text', required: true, label: 'Post/Fonction' },
          { asCol: false, name: 'Ville', type: 'text', required: true, label: 'Ville/Adresse' },
        ],
      },
      {
        title: 'Vos motivivations',
        description: '',
        fields: [
          {
            asCol: false,
            flexWrapOptions: false,
            name: 'Workplace',
            type: 'radio',
            required: true,
            label: 'Lieu de travail',
            placeholder: 'cliquer pour sélectionner',
            options: ['Présentiel', 'Remote/Distance'],
          },
          {
            asCol: false,
            name: 'Profil',
            type: 'radio',
            required: true,
            label: 'Profile Recherché',
            options: ['Développeur', 'Marketing Digital'],
          },
          {
            asCol: false,
            name: 'Durrée',
            type: 'radio',
            required: true,
            label: 'Durrée de stage',
            options: ['3 mois', '6 mois '],
          },
          {
            dependsOn: { field: 'Profil', value: 'Marketing Digital' },
            flexWrapOptions: true,
            name: 'Outils',
            type: 'checkbox-list',
            required: true,
            label: 'Quels outils SMD utilisez-vous en interne?',
            options: [
              "Création D'interfaces Web Statiques",
              'Javascript and Interactive web pages',
              'Backend development with Node.js',
              'Databases',
              'SDLC & Wordpress',
              'Data structures, algorithms and tech interview',
              'Web security',
              'Meta Business Suite',
              'Graphisme ( Photoshop, Illustrator, Canva, Figma )',
              'Facebook ads',
              'Google ads',
              'Analytics ( Semrush, Google analytics, Hootsuite )',
              'Mailing ( Hubspot, Mailchimp )',
            ],
          },
          {
            dependsOn: { field: 'Profil', value: 'Développeur' },
            flexWrapOptions: true,
            name: 'Outils',
            type: 'checkbox-list',
            required: true,
            label: 'Quels outils Dev utilisez-vous en interne?',
            options: [
              'PHP',
              'JavaScript/TypeScript',
              'Java',
              'Python',
              'C#',
              'Dart',
              'Ruby',
              'Swift',
              'Kotlin',
              'SQL',
              'HTML, CSS',
            ],
          },
          {
            conditioned: true,
            name: 'Critéres',
            type: 'textarea',
            required: false,
            label: 'Avez-vous des critères spécifiques',
          },
          {
            dependsOn: { field: 'Profil', value: 'Marketing Digital' },
            flexWrapOptions: true,
            name: 'Projets',
            type: 'checkbox-list',
            required: true,
            label: 'Sur quel projet souhaitez-vous travailler?',
            options: [
              'Stratégie de marketing Digital',
              'Développement de site web avec WordPress',
              'Search Engine Marketing (Référencement)',
              'Publicité en ligne',
              'Marketing de contenu',
              'Marketing des médias sociaux',
              'E-mail Marketing',
              'Analyse de données, Suivi des performances et Reporting',
              'Web & Graphic Design',
              'Growth Hacking et CRM',
              'E-reputation',
            ],
          },
          {
            dependsOn: { field: 'Profil', value: 'Développeur' },
            flexWrapOptions: true,
            name: 'Projets',
            type: 'checkbox-list',
            required: true,
            label: 'Sur quel projet souhaitez-vous travailler?',
            options: [
              "Création d'interfaces Web Statiques",
              'Javascript and Interactive web pages',
              'SDLC & Wordpress',
              'Backend development with Node.js',
              'Databases',
              'Web Security',
              'Data structures, algorithms and tech interview',
              'Capstone',
            ],
          },
          {
            flexWrapOptions: true,
            name: 'Attentes',
            type: 'checkbox-list',
            required: true,
            label: 'Quelles sont vos attentes en prenant un stagiaire?',
            options: [
              'Développer votre présence en ligne',
              'Mettre en place des process digitaux',
              'Développement applications et site web.',
              'Travailler sur des projets en cours',
            ],
          },
          {
            flexWrapOptions: true,
            name: 'How_Heard',
            type: 'radio',
            required: true,
            label: 'Quelles sont vos attentes en prenant un stagiaire?',
            options: [
              'Social media',
              'Google',
              'Bouche à oreille',
              'E-mail',
              'Personne de contact KADEA',
              'Annonces (Mediacongo, Bizcongo, etc.)',
            ],
          },
        ],
      },
    ],
  },
  {
    type: FormType.MEETUP,
    title: 'Animer un meet-up',
    description: 'description lamda',
    list: ['', '', ''],
    fieldsets: [
      {
        title: "Identité de l'Entreprise",
        description: 'Nous souhaitons en connître un peu plus sur ton entreprise',
        fields: [
          { name: 'Entreprise', type: 'text', required: true, label: 'Nom de lentreprise' },
          { name: 'Secteur', type: 'text', required: true, label: "Secteur d'activité" },
          {
            name: 'Type-Entreprise',
            type: 'radio',
            required: true,
            label: 'Type d’organisation',
            options: ['Startup', 'PME', 'Grand groupe', 'ONG', 'Public'],
            placeholder: 'sélection',
          },
          {
            name: 'Taille-Entreprise',
            type: 'radio',
            required: true,
            label: "Taille de l'entreprise",
            options: ['1-10', '11-50', '51-100', '100+'],
          },
        ],
      },
      {
        title: 'Personne de contact',
        description: 'Avec qui serons-nous en contact pour tout les échanges futures',
        fields: [
          {
            asCol: false,
            name: 'Nom-du-Contact',
            type: 'text',
            required: false,
            label: 'Nom & Prénom',
          },
          { asCol: false, name: 'Email', type: 'email', required: true, label: 'Adresse email' },
          { asCol: false, name: 'Téléphone', type: 'tel', required: true, label: 'Téléphone' },
          { asCol: false, name: 'Post', type: 'text', required: true, label: 'Post/Fonction' },
          { asCol: false, name: 'Ville', type: 'text', required: true, label: 'Ville/Adresse' },
        ],
      },
      {
        title: 'Vos motivivations',
        description: '',
        fields: [
          {
            asCol: false,
            name: 'Objectif',
            type: 'select',
            required: true,
            label: 'Objectif',
            placeholder: 'cliquer pour sélectionner',
            options: [
              'Partage d’expérience',
              'Présentation d’un projet ',
              'Recruter des talents pour le renforcement de vos équipes',
            ],
          },
          {
            asCol: false,
            name: 'Expérience',
            type: 'textarea',
            required: true,
            label: 'Décrivez brièvement votre expérience professionnelle',
          },
          {
            asCol: false,
            name: 'Projet',
            type: 'textarea',
            required: true,
            label: 'Décrivez brièvement votre projet (présentation)',
          },
          {
            flexWrapOptions: true,
            asCol: true,
            name: 'Challenges',
            type: 'checkbox-list',
            required: true,
            label: 'Quel challenge rencontrez-vous actuellement dans votre entreprise',
            options: [
              'Développer votre présence en ligne',
              'Mettre en place des process digitaux',
              'Développement applications et site web.',
              'Travailler sur des projets en cours',
            ],
          },
          {
            asCol: false,
            name: 'Expertise',
            type: 'radio',
            required: true,
            label: 'Profile Recherché',
            options: ['Développeur', 'Marketing Digital '],
          },
        ],
      },
    ],
  },
]
const ModalForms: FC<Props> = ({ onClose, formType }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const currentForm = useMemo(() => FORMS.find((form) => form.type === formType), [formType])
  const formRef = useRef<HTMLFormElement | null>(null)
  const formdata = useRef<any>({})
  const [status, setStatus] = useState<'error' | 'succed' | 'loading' | 'pending' | 'collect'>(
    'collect',
  )

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()

      setStatus('loading')
      submitForm(formType, formdata.current)
        .then(() => setTimeout(() => setStatus('succed'), 1000))
        .catch(() => setTimeout(() => setStatus('error'), 1000))
    },
    [formType],
  )

  const onChange = useCallback((e: any) => {
    formdata.current = { ...formdata.current, [e.target.name]: e.target.value }
  }, [])
  const validateEntries = useCallback(() => {
    let isValid = true
    Array.from(formRef.current?.elements!).forEach((input: any) => {
      let inputIsValid = true
      const inputValue = formdata.current[input.getAttribute('name') || '']
      const inputIsRequired = input.classList.contains('required')
      const inputName = input.getAttribute('name')
      const inputPattern = input.getAttribute('data-pattern')

      if (inputIsRequired && !inputValue) {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-required`)
          ?.classList.remove('hidden')
      } else {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-required`)
          ?.classList.add('hidden')
      }

      if (inputIsRequired) {
        inputIsValid &&= inputValue !== undefined && inputValue !== ''
        isValid &&= inputValue !== undefined && inputValue !== ''
      }

      // error-invalid
      if (inputIsValid && inputPattern && !new RegExp(inputPattern).test(inputValue)) {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-invalid`)
          ?.classList.remove('hidden')
      } else {
        formRef.current
          ?.querySelector(`.control-label-${inputName} span.error-invalid`)
          ?.classList.add('hidden')
      }

      if (inputPattern) {
        isValid &&= new RegExp(inputPattern).test(inputValue)
      }
    })

    if (isValid) {
      if (currentStep < currentForm!.fieldsets.length - 1) {
        setCurrentStep((prev) => prev + 1)
      } else {
        formRef.current?.requestSubmit()
      }
    }
  }, [currentForm, currentStep])

  const RenderForm = useCallback(
    () => (
      <form ref={formRef} onSubmit={onSubmit} className='flex flex-col gap-y-4'>
        <h3 className={globalClassNames.h3}>{currentForm!.fieldsets[currentStep].title}</h3>
        <p className='text-xl'>{currentForm!.fieldsets[currentStep].description}</p>
        {currentForm!.fieldsets[currentStep].fields?.map(({ asCol = true, ...field }: any, key) => (
          <CustomInput
            id={`fieldset-${currentStep}-fieldname-${field.name}-fieldkey-${key}`}
            key={key.valueOf()}
            {...field}
            autoFocus={key === 0}
            defaultValue={formdata.current[field.name]}
            onChange={onChange}
            asCol={asCol}
          />
        ))}
        <div className='mt-2 md:mt-5 flex gap-2 md:gap-5 justify-end'>
          <Button
            label='retour'
            varient='black'
            disabled={currentStep <= 0}
            icon={<BsArrowLeft />}
            iconBeforeText
            showIcon
            onClick={() => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev))}
          />
          <Button
            label={currentStep < 2 ? 'suivant' : 'enregistrer'}
            varient='primary'
            icon={currentStep < currentForm!.fieldsets.length - 1 ? undefined : <AiOutlineSave />}
            showIcon
            onClick={validateEntries}
          />
        </div>
      </form>
    ),
    [currentForm, currentStep, onChange, onSubmit, validateEntries],
  )

  useEffect(() => {
    const onkeydown = (e: any) => {
      if (e.key?.toLowerCase() === 'escape' || e.key?.toLowerCase() === 'esc') {
        onClose()
      }
    }
    document.addEventListener('keydown', onkeydown)
    document.body.classList.add('overflow-hidden')
    return () => {
      document.removeEventListener('keydown', onkeydown)
      document.body.classList.remove('overflow-hidden')
    }
  }, [onClose])

  useEffect(() => {
    if (status === 'loading') {
      setTimeout(() => {
        setStatus('succed')
      }, 4000)
    }
  }, [formdata, status])

  return (
    <PopupModal
      onClose={onClose}
      className={`grid xl:min-w-[40rem] ${currentForm!.maxW || 'max-w-3xl'} rounded-xl bg-white`}
    >
      <div className='relative z-10 rounded-xl flex flex-col gap-y-3 bg-primary py-5 px-5 text-white drop-shadow-2xl'>
        <h2 className={`${globalClassNames.h2} max-md:font-normal `}>{currentForm!.title}</h2>
        {/* <p className='lg:text-lg'>{ FORMS[form].description }</p> */}
      </div>
      <div className='p-5 relative'>
        {status === 'collect' && <RenderForm />}
        {status === 'loading' && (
          <div className='flex flex-col items-center justify-center'>
            <p className='text-gray-darker'>inscription...</p>
            <BiLoaderAlt className='text-gray text-9xl animate-spin' />
          </div>
        )}

        {status === 'succed' && (
          <div className=' flex-1 overflow-hidden'>
            <div className='absolute inset-0 w-full h-full'>
              <StaticImage
                placeholder='blurred'
                objectFit='cover'
                className='opacity-20 grayscale object-cover scale-y-150 translate-y-1/3'
                src='../../../images/background-images/motifs.svg'
                alt='homme-africain-noir-touristique-casque-dos-lunettes-soleil-voyageant'
              />
            </div>
            <div className='z-10 flex flex-col justify-center gap-y-5 md:gap-y-10 py-5 md:py-10'>
              <BsFillPatchCheckFill className=' text-8xl text-primary mx-auto' />
              <h3 className='md:text-center text-xl md:text-2xl md:font-semibold'>
                Merci pour l&apos;interêt porté à la{' '}
                <span className='text-primary'>Kadea Academy</span>
              </h3>
              <p className='text-center md:text-xl'>
                Un mail a été envoyé à ton adresse{' '}
                <span className='font-semibold'>{formdata.current.Email}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </PopupModal>
  )
}

export default ModalForms
