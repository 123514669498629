/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import IMask from 'imask'
import { GoTriangleDown } from 'react-icons/go'
import { FaCheck } from 'react-icons/fa'
import raiseCustomEvent from '../../utils/event'

export type CustomInputProps = {
  label?: string
  name: string
  id?: string
  placeholder?: string
  type?: 'text' | 'email' | 'tel' | 'select' | 'date' | 'radio' | 'textarea' | 'checkbox-list'
  maxLength?: number
  minLength?: number
  options?: string[] | { label: string; value?: string; disabled?: boolean }[]
  onChange?: (e: any) => void
  required?: boolean
  onEmptyMessage?: string
  defaultValue?: string
  rowOnMobile?: boolean
  asCol?: boolean
  flexWrapOptions?: boolean
  flexCol?: boolean
  conditioned?: boolean
  dependsOn?: { field: string; value: string }
  autoFocus?: boolean
  addWrapper?: boolean
}

const CustomInput: FC<CustomInputProps> = ({
  id,
  label,
  onChange,
  defaultValue,
  name,
  placeholder,
  type,
  maxLength,
  minLength,
  options = [],
  required,
  rowOnMobile,
  asCol,
  flexWrapOptions,
  conditioned,
  dependsOn,
  autoFocus,
  flexCol,
  addWrapper,
}) => {
  const handleOnChange = useCallback(
    (e: any) => {
      raiseCustomEvent('custom-field-value-change', { field: e.target.name, value: e.target.value })

      if (onChange) {
        onChange(e)
      }
    },
    [onChange],
  )

  const [isContionOkey, setIsContionOkey] = useState(defaultValue !== undefined)
  const inputRef = useRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null>(null)

  const RenderNormalInput = useCallback(
    () => (
      <div
        className={`w-full lg:w-auto flex-1 flex items-center justify-between rounded-md ${
          type !== 'tel' ? ' bg-white' : 'h-12'
        } border border-gray-light/50 overflow-hidden`}
      >
        {type === 'textarea' ? (
          <textarea
            defaultValue={defaultValue}
            onChange={handleOnChange}
            name={name}
            maxLength={300}
            id={id}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={`${
              required ? 'required' : ''
            } input p-3 h-24 outline-none flex-1 text-slate-500 placeholder:text-slate-400`}
          />
        ) : (
          <>
            {type === 'tel' && (
              <div className='flex items-center h-12 px-3 bg-primary/70 text-white'>+243</div>
            )}
            <input
              defaultValue={defaultValue}
              // required={required}
              data-pattern={type === 'email' ? '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$' : ''}
              onChange={handleOnChange}
              // type={type}
              name={name}
              maxLength={maxLength}
              minLength={minLength}
              id={id}
              placeholder={placeholder}
              autoFocus={autoFocus}
              className={`${required ? 'required' : ''} input px-3 outline-none flex-1 h-12 ${
                type === 'tel' ? 'bg-white' : 'bg-transparent placeholder:text-slate-300'
              } text-slate-500 placeholder:text-slate-400`}
            />
          </>
        )}
      </div>
    ),
    [
      defaultValue,
      autoFocus,
      handleOnChange,
      id,
      maxLength,
      minLength,
      name,
      placeholder,
      required,
      type,
    ],
  )

  const RenderCheckboxList = useCallback(() => {
    const handleOnChecked = (e: any) => {
      const values = inputRef.current?.value

      if (values?.includes(e.target.value)) {
        inputRef.current!.value = inputRef?.current?.value?.replace(`${e.target?.value};`, '') || ''
      } else {
        inputRef.current!.value = inputRef?.current?.value?.concat(`${e.target?.value};`) || ''
      }

      handleOnChange({ target: { name, value: inputRef.current!.value } })
    }
    return (
      <div
        className={`${
          flexWrapOptions ? 'flex-wrap' : ''
        } text-sm flex items-center gap-x-2.5 sm:gap-x-5`}
      >
        <input
          className={required ? 'required' : ''}
          ref={inputRef as any}
          type='hidden'
          defaultValue={defaultValue}
          name={name}
          onChange={handleOnChange}
        />
        {options.map((opt: any, key) => (
          <label
            autoFocus={autoFocus}
            htmlFor={`${type}-${name}-${key}`}
            className='h-6 flex gap-x-1 items-center cursor-pointer capitalize text-black'
          >
            <input
              onChange={handleOnChecked}
              className='peer hidden'
              defaultChecked={defaultValue?.includes(opt?.value || opt)}
              key={key.valueOf()}
              id={`${type}-${name}-${key}`}
              name={`opts-${name}-${key}`}
              value={opt?.value || opt}
              type='checkbox'
            />
            <div className='w-5 h-5 bg-gray rounded block peer-checked:hidden' />
            <div className='w-5 h-5 bg-primary rounded hidden peer-checked:flex items-center justify-center'>
              <FaCheck className='text-white text-xs' />
            </div>
            <span className='flex-1 '>{opt?.label || opt}</span>
          </label>
        ))}
      </div>
    )
  }, [defaultValue, autoFocus, flexWrapOptions, handleOnChange, name, options, required, type])

  const RenderRadioList = useCallback(
    () => (
      <div
        className={`${flexWrapOptions ? 'flex-wrap' : ''} ${
          flexCol ? 'flex-col gap-y-3' : 'items-center'
        } text-sm flex gap-x-2.5 lg:gap-x-5`}
      >
        {options.map((opt: any, key) => {
          const onRadioClick = () => {
            document.getElementById(`${type}-${name}-${key}`)?.click()
          }
          return (
            <button
              type='button'
              autoFocus={autoFocus && key === 0}
              key={key.valueOf()}
              className={`${
                addWrapper ? 'py-4 px-2 border border-gray rounded' : ''
              } h-8 flex gap-x-1 items-center cursor-pointer capitalize text-left text-black`}
              onClick={onRadioClick}
            >
              <input
                onChange={handleOnChange}
                className={`${required ? 'required' : ''} input peer hidden`}
                defaultChecked={defaultValue === (opt?.value || opt)}
                key={key.valueOf()}
                id={`${type}-${name}-${key}`}
                name={name}
                type='radio'
                value={opt?.value || opt}
              />
              <span className='inline-block w-6 h-6 rounded-full bg-gray peer-checked:bg-primary relative peer-checked:after:bg-white after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:rounded-full' />
              <span className='flex-1 '>{opt?.label || opt}</span>
            </button>
          )
        })}
      </div>
    ),
    [
      flexWrapOptions,
      flexCol,
      addWrapper,
      autoFocus,
      options,
      type,
      name,
      handleOnChange,
      required,
      defaultValue,
    ],
  )

  const RenderSelectbox = useCallback(
    () => (
      <div className='w-full sm:w-auto flex-1 text-slate-500 placeholder:text-slate-400 relative border bg-white border-gray-light flex items-center justify-between rounded-md'>
        <select
          defaultValue={defaultValue || placeholder}
          // required={required}
          autoFocus={autoFocus}
          onChange={handleOnChange}
          name={name}
          id={id}
          className={`${
            required ? 'required' : ''
          } input px-5 relative z-10 w-full flex-1 bg-transparent h-full py-3 outline-none appearance-none`}
        >
          <option className='appearance-none' disabled>
            {' '}
            {placeholder}{' '}
          </option>
          {options.map((opt: any) => {
            const { label: optLabel, value, disabled } = opt
            return (
              <option
                key={value || optLabel || opt}
                disabled={disabled}
                className=' appearance-none'
                value={value || optLabel || opt}
              >
                {optLabel || opt}
              </option>
            )
          })}
        </select>
        <GoTriangleDown className='absolute right-2 text-primary' />
      </div>
    ),
    [handleOnChange, autoFocus, options, placeholder, name, required, defaultValue, id],
  )

  const [isDependsOnFieldOkey, setIsDependsOnFieldOkey] = useState(
    defaultValue !== undefined || dependsOn === undefined,
  )

  useEffect(() => {
    if (type === 'tel') {
      const masked = IMask(document.getElementById(id!)!, { mask: '000-000-000' })
      masked?.on('accept', () => {
        handleOnChange({ target: { name, value: masked.unmaskedValue } })
      })
    }
  }, [type, id, name, handleOnChange])

  useEffect(() => {
    // if(isContionOkey && onChange){
    //   onChange({ target :{name, value: defaultValue || ""}})
    // }

    if (onChange) {
      onChange({ target: { name, value: isContionOkey ? defaultValue || '' : undefined } })
    }
  }, [isContionOkey, onChange, name, defaultValue])

  useEffect(() => {
    if (dependsOn) {
      inputRef?.current?.setAttribute('depends-on-field-name', dependsOn.field)
      inputRef?.current?.setAttribute('depends-on-field-value', dependsOn.value)
    }
    const onCustomFieldChanged = ({ detail }: any) => {
      if (dependsOn && dependsOn.field === detail?.field) {
        setIsDependsOnFieldOkey(dependsOn.value === detail?.value)
      }
    }
    document.addEventListener('custom-field-value-change', onCustomFieldChanged)

    return () => {
      document.removeEventListener('custom-field-value-change', onCustomFieldChanged)
    }
  }, [dependsOn])

  return !isDependsOnFieldOkey ? (
    <></>
  ) : (
    <div
      className={`input-control control-${name} flex ${
        asCol ? 'flex-col' : !rowOnMobile ? 'flex-col sm:flex-row sm:items-center' : 'items-center'
      } gap-y-1 gap-x-2.5 sm:gap-x-5`}
    >
      <div className='flex items-center gap-x-10 w-fit'>
        {label && (
          <label className={`control-label-${name} ${!asCol ? 'md:w-36' : ''}`} htmlFor={id}>
            <span>{label}</span> {!asCol && <br className='max-md:hidden' />}
            {required && <span className='hidden error-required text-primary'>recquis *</span>}
            <span className='hidden error-invalid text-primary'>format inavlide</span>
          </label>
        )}

        {conditioned && (
          <div
            className={`${
              flexWrapOptions ? 'flex-wrap' : ''
            } text-sm flex items-center gap-x-2.5 sm:gap-x-5`}
          >
            <label
              className='h-8 flex gap-x-1 items-center cursor-pointer capitalize text-black'
              htmlFor={`${type}-${name}-condition-yes`}
            >
              <input
                onChange={() => setIsContionOkey(true)}
                className={`${required ? 'required' : ''} input peer hidden`}
                defaultChecked={isContionOkey}
                id={`${type}-${name}-condition-yes`}
                name={name}
                type='radio'
              />
              <span className='inline-block w-6 h-6 rounded-full bg-gray peer-checked:bg-primary relative peer-checked:after:bg-white after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:rounded-full' />
              <span className='flex-1 '>Oui</span>
            </label>
            <label
              className='h-8 flex gap-x-1 items-center cursor-pointer capitalize text-black'
              htmlFor={`${type}-${name}-condition-no`}
            >
              <input
                onChange={() => setIsContionOkey(false)}
                className={`${required ? 'required' : ''} input peer hidden`}
                defaultChecked={defaultValue !== undefined && !isContionOkey}
                id={`${type}-${name}-condition-no`}
                name={name}
                type='radio'
              />
              <span className='inline-block w-6 h-6 rounded-full bg-gray peer-checked:bg-primary relative peer-checked:after:bg-white after:absolute after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:rounded-full' />
              <span className='flex-1 '>Non</span>
            </label>
          </div>
        )}
      </div>

      {(!conditioned || isContionOkey) && (
        <div className={`${conditioned ? 'w-full' : 'flex-1'}`}>
          {type !== 'select' && type !== 'radio' && type !== 'checkbox-list' && (
            <RenderNormalInput />
          )}
          {type === 'radio' && <RenderRadioList />}
          {type === 'checkbox-list' && <RenderCheckboxList />}
          {type === 'select' && <RenderSelectbox />}
        </div>
      )}
      {/* { ( type !== "select") && ( type !== "radio") && (type !== "checkbox-list") && <RenderNormalInput /> }
        { (type === "radio") && <RenderRadioList />}
        { (type === "checkbox-list") && <RenderCheckboxList />}
        { (type === "select") && <RenderSelectbox /> } */}
    </div>
  )
}

CustomInput.defaultProps = {
  id: (Date.now() + Math.random()).toString(),
  label: undefined,
  placeholder: undefined,
  type: 'text',
  maxLength: undefined,
  minLength: 2,
  options: [],
  required: undefined,
  onChange: undefined,
  onEmptyMessage: undefined,
  defaultValue: undefined,
  rowOnMobile: false,
  asCol: false,
  flexWrapOptions: false,
  flexCol: false,
  addWrapper: false,
  conditioned: false,
  dependsOn: undefined,
  autoFocus: false,
}
export default CustomInput
