import React, { useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import PopupModal from '../../molecules/PopupModal'

const YoutubeVideoPlayer = ({ onClose }: { onClose: () => void }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  return (
    <PopupModal onClose={onClose} className='w-[90vw] h-[70vh] lg:h-[80vh] md:max-w-3xl bg-black'>
      {!hasLoaded && (
        <div className='w-full h-full flex-1 flex items-center justify-center'>
          <BiLoaderAlt className='text-white text-9xl animate-spin' />
        </div>
      )}
      <div className={!hasLoaded ? 'hidden' : 'w-full h-full flex-1'}>
        <iframe
          onLoad={() => setHasLoaded(true)}
          className='w-full h-full'
          src='https://www.youtube.com/embed/1j96jmqJhJY?autoplay=1&color=white'
          title='_'
          allow='accelerometer; autoplay; clipboard-write;'
          allowFullScreen
        />
      </div>
    </PopupModal>
  )
}

export default YoutubeVideoPlayer
