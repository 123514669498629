import React, { FC } from 'react'

type Props = { item: string; icon?: any }

const ListItem: FC<Props> = ({ item, icon }) => (
  <li className='flex md:py-1'>
    {icon && <span className='translate-y-1.5 text-primary mr-3'>{icon}</span>}
    <span className='flex-1'>{item}</span>
  </li>
)

ListItem.defaultProps = {
  icon: undefined,
}
export default ListItem
