import { useState } from 'react'

const useMousseOn = () => {
  const [mouseOn, setMousseOn] = useState(false)
  const toggleMouseOn = () => setMousseOn(!mouseOn)

  return { mouseOn, toggleMouseOn }
}

export default useMousseOn
